<template>

  <AddButtons ref="AddButtons" :source-org-guid="sourceOrgGuid" :source-org-title="sourceOrgTitle"/>

</template>

<script>
import $ from "jquery";
import {v4 as uuidv4} from "uuid";
import {DataFilters, Helper} from "@xnpmpackages/xcomponents";
import AddButtons from "@/components/Constructor/AddButtons";

$.fn.extend({
  setValueIfEmpty: function (val) {
    return this.each(function () {

      if (!$(this)?.val())
        $(this).val(val)

    });
  },
});

export default {
  name: "EnrichFrame",
  components: {AddButtons},
  props: {
    sourceOrgGuid: null,
    sourceOrgTitle: null,
    selector: null,
    context: null,
    autoSelectProcess: null,
  },
  data() {
    return {
      ElectronicDocumentsTypes: null,
      InformationInteractionsEvents: null,
      InformationInteractionsTypes: null,
    }
  },
  methods: {

    enrichFrame($iframe) {

      //this._addEventListenerToNeedRegisterChange($iframe)
      this._insertHeaderControlBar($iframe)
      this._fillProcess($iframe)
      this._fillEvent($iframe)
      this._fillAgents($iframe)
      this._fillDocHeader($iframe)
      this._fillXmlId($iframe)
      this._hideSelectParentProcess($iframe)
      this._addClassToEventBlocks($iframe)
      this._hideOtherInformation($iframe)
      this._insertCollapseEventButtons($iframe)


      $iframe.contentWindow.updateIdentifiers();

      this.$refs.AddButtons.addButtons($iframe, this.context);
      this._insertDataAboutProcessIfSelected($iframe, this.autoSelectProcess)

      if (typeof $iframe?.contentWindow?._fillCalculatedFields === 'function')
        $iframe?.contentWindow?._fillCalculatedFields();

    },

    _addEventListenerToNeedRegisterChange($iframe) {

      let needRegistrationSelect = $iframe.contentDocument.querySelector('[data-xsd2html2xml-xpath-without-ns="/Документ/ДанныеДокумента/ЗаголовокДокумента/ТребуетРегистрации"] select')
      let needDspSelect = $iframe.contentDocument.querySelector('[data-xsd2html2xml-xpath-without-ns="/Документ/ДанныеДокумента/ЗаголовокДокумента/СодержитСведенияДСП"] select')
      //let needSignSelect = $iframe.contentDocument.querySelector('[data-xsd2html2xml-xpath-without-ns="/Документ/ДанныеДокумента/ЗаголовокДокумента/ТребуетПодписания"] select')

      if (!needRegistrationSelect)
        return;

      let changeValueListener = () => {

        let needRegistrationSelectValue
        let needDspSelectSelectValue

        if (needRegistrationSelect)
          needRegistrationSelectValue = needRegistrationSelect.value === 'Да'

        if (needDspSelect)
          needDspSelectSelectValue = needDspSelect.value === 'Да'

        // Если требует регистрации или ДСП и нет блока регистрации, добавляем блок регистрации
        if (needRegistrationSelectValue || needDspSelectSelectValue) {

          if (!$iframe.contentDocument.querySelector('[data-xsd2html2xml-xpath-without-ns="/Документ/РегистрацияДокумента"]:not([hidden])')) {
            $iframe.contentDocument.querySelector('[data-xsd2html2xml-xpath-without-ns="/Документ/РегистрацияДокумента"]').parentElement.querySelector(':scope > button.add').click();
            $iframe.contentDocument.querySelector('[data-xsd2html2xml-xpath-without-ns="/Документ/РегистрацияДокумента"]:not([hidden]) [is-choice-input="true"]').click();
          }

          // Если нужен блок "НаправлениеИсходящего", а его нет, добавляем его
          if (needRegistrationSelectValue && !$iframe.contentDocument.querySelector('[data-xsd2html2xml-xpath-without-ns="/Документ/РегистрацияДокумента/НаправлениеИсходящего"]:not([hidden])'))
            $($iframe.contentDocument.querySelector('[data-xsd2html2xml-xpath-without-ns="/Документ/РегистрацияДокумента/НаправлениеИсходящего"]')).siblings('button.add').click();

          // Если нужен блок "ОграничениеДоступа", а его нет, добавляем его
          if (needDspSelectSelectValue && !$iframe.contentDocument.querySelector('[data-xsd2html2xml-xpath-without-ns="/Документ/РегистрацияДокумента/ОграничениеДоступа"]:not([hidden])'))
            $($iframe.contentDocument.querySelector('[data-xsd2html2xml-xpath-without-ns="/Документ/РегистрацияДокумента/ОграничениеДоступа"]')).siblings('button.add').click();

        }

        // Если не нужен блок "НаправлениеИсходящего", а он есть, удаляем его
        if (!needRegistrationSelectValue && $iframe.contentDocument.querySelector('[data-xsd2html2xml-xpath-without-ns="/Документ/РегистрацияДокумента/НаправлениеИсходящего"]:not([hidden])'))
          $($iframe.contentDocument.querySelector('[data-xsd2html2xml-xpath-without-ns="/Документ/РегистрацияДокумента/НаправлениеИсходящего"] > legend > button.remove')).click();

        // Если не нужен блок "ОграничениеДоступа", а он есть, удаляем его
        if (!needDspSelectSelectValue && $iframe.contentDocument.querySelector('[data-xsd2html2xml-xpath-without-ns="/Документ/РегистрацияДокумента/ОграничениеДоступа"]:not([hidden])'))
          $($iframe.contentDocument.querySelector('[data-xsd2html2xml-xpath-without-ns="/Документ/РегистрацияДокумента/ОграничениеДоступа"] > legend > button.remove')).click();

        // Если не требуется регистрация и ДСП есть блок регистрации, удаляем блок регистрации
        if (!needRegistrationSelectValue && !needDspSelectSelectValue && $iframe.contentDocument.querySelector('[data-xsd2html2xml-xpath-without-ns="/Документ/РегистрацияДокумента"]:not([hidden])'))
          $iframe.contentDocument.querySelector('[data-xsd2html2xml-xpath-without-ns="/Документ/РегистрацияДокумента"]:not([hidden]) > legend button.remove').click();

        this.fillRegistrationByDummies()


      }

      needRegistrationSelect.removeEventListener('change', changeValueListener)
      needRegistrationSelect.addEventListener('change', changeValueListener)
      //needSignSelect.addEventListener('change', changeSignSelectListener)

      needDspSelect.removeEventListener('change', changeValueListener)
      needDspSelect.addEventListener('change', changeValueListener)

      changeValueListener();

    },

    _fillProcess($iframe) {

      let _event_info = $($iframe.contentDocument).find('[data-xsd2html2xml-namewithoutns="ИнформацияОСобытии"]');

      _event_info.each((index, item) => {

        let _event_status = $(item).find('[data-xsd2html2xml-namewithoutns="СтатусСобытия"]:first select:first').val();

        if (!_event_status)
          return;

        let _proc_info = $(item).parent().parent().find('[data-xsd2html2xml-namewithoutns="ИнформацияОПроцессе"]')

        if (!_proc_info)
          return;

        if ($(item).parents('[hidden]').length)
          return;

        $(_proc_info).find('[data-xsd2html2xml-namewithoutns="ВидИД"]').addClass('serviceInfo');
        $(_proc_info).find('[data-xsd2html2xml-namewithoutns="ВидНазвание"]').addClass('serviceInfo');

        if (_event_status === 'Инициация') {

          let process_timeCreated, process_guid;

          process_guid = uuidv4();
          process_timeCreated = DataFilters.methods.getCurrentDateTimeISO();

          $(_proc_info).children('section').children('label').children('[data-xsd2html2xml-namewithoutns="ВремяСоздания"] input').val(process_timeCreated).trigger('change').prop('disabled', true).addClass('disabled').parent('label').addClass('serviceInfo');

          const _procUUID = $(_proc_info).children('section').children('[data-xsd2html2xml-namewithoutns="СсылкаПроцесса"]').find('[data-xsd2html2xml-namewithoutns="ПроцессУУИД"] input')
          _procUUID[0].setAttribute('value', process_guid)
          _procUUID.val(process_guid).prop('disabled', true).addClass('disabled').parent('label').addClass('serviceInfo');
          $(_proc_info).children('section').children('[data-xsd2html2xml-namewithoutns="СсылкаПроцесса"]').find('[data-xsd2html2xml-namewithoutns="Представление"] input').prop('disabled', true).addClass('disabled')
          $(_proc_info).children('section').children('[data-xsd2html2xml-namewithoutns="СсылкаПроцесса"]').addClass('serviceInfo');


          $(_proc_info).children('section').children('[data-xsd2html2xml-namewithoutns="СсылкаСоздателя"]').addClass('serviceInfo');

          $(_proc_info).children('section').children('[data-xsd2html2xml-namewithoutns="СсылкаСоздателя"]').find('[data-xsd2html2xml-namewithoutns="АгентУУИД"]:first input:first').setValueIfEmpty(this.sourceOrgGuid).prop('disabled', true).addClass('disabled');
          $(_proc_info).children('section').children('[data-xsd2html2xml-namewithoutns="СсылкаСоздателя"]').find('[data-xsd2html2xml-namewithoutns="Представление"]:first input:first').setValueIfEmpty(this.sourceOrgTitle).prop('disabled', true).addClass('disabled').trigger('change');

        } else {

          $(_proc_info).children('section').children('[data-xsd2html2xml-namewithoutns="СсылкаПроцесса"]').addClass('serviceInfo');

          $(_proc_info).find('[data-xsd2html2xml-namewithoutns="ВремяСоздания"]:first input:first').prop('disabled', true).addClass('disabled').parent('label').addClass('serviceInfo');
          $(_proc_info).find('[data-xsd2html2xml-namewithoutns="СсылкаПроцесса"] [data-xsd2html2xml-namewithoutns="ПроцессУУИД"]:first input:first').prop('disabled', true).addClass('disabled').parent('label').addClass('serviceInfo');
          $(_proc_info).find('[data-xsd2html2xml-namewithoutns="СсылкаПроцесса"] [data-xsd2html2xml-namewithoutns="Представление"]:first input:first').prop('disabled', true).addClass('disabled').parent('label').addClass('serviceInfo');
          $(_proc_info).find('[data-xsd2html2xml-namewithoutns="СсылкаСоздателя"]').addClass('serviceInfo');
          $(_proc_info).find('[data-xsd2html2xml-namewithoutns="СсылкаСоздателя"] [data-xsd2html2xml-namewithoutns="АгентУУИД"]:first input:first').prop('disabled', true).addClass('disabled');
          $(_proc_info).find('[data-xsd2html2xml-namewithoutns="СсылкаСоздателя"] [data-xsd2html2xml-namewithoutns="Представление"]:first input:first').prop('disabled', true).addClass('disabled');

        }

      });

    },

    _fillEvent($iframe) {

      $iframe.contentDocument.querySelectorAll('[data-xsd2html2xml-namewithoutns="ИнформацияОСобытии"]')?.forEach(item => {

        const $timeElement = item.querySelector('[data-xsd2html2xml-namewithoutns="ВремяСобытия"] input')

        if (!$timeElement.hasAttribute('data-xsd2html2xml-filled')) {
          $timeElement.value = DataFilters.methods.getCurrentDateTimeISO();
          $(item).find('[data-xsd2html2xml-namewithoutns="ВремяСобытия"]:first input:first').trigger('change');
        }

        let $event_type_uuid = item.querySelector('[data-xsd2html2xml-namewithoutns="СобытиеУУИД"]').querySelector('input, select, textarea');
        let $event_type_name = item.querySelector('[data-xsd2html2xml-namewithoutns="Представление"]').querySelector('input, select, textarea');

        //if (!$event_type_name.hasAttribute('data-xsd2html2xml-filled'))
        //    $event_type_name.value = 'ТУТ НАДО АВТОФИЛИТЬ'; //this.getInformationInteractionEventById(this.selector.InformationInteractionsEvent)?.['@attributes']?.['Название'];


        $event_type_name.disabled = true;
        $(item).find('[data-xsd2html2xml-namewithoutns="Название"]').addClass('serviceInfo');

        if (!$event_type_uuid.hasAttribute('data-xsd2html2xml-filled'))
          $event_type_uuid.value = this.getInformationInteractionEventById(this.selector.InformationInteractionsEvent)?.['@attributes']?.['ИД'];


        $event_type_uuid.disabled = true;
        $(item).find('[data-xsd2html2xml-namewithoutns="ИД"]').addClass('serviceInfo');

        let $event_uuid = $(item).find('[data-xsd2html2xml-namewithoutns="СобытиеУУИД"]');
        $event_uuid.addClass('serviceInfo');

        let new_uuid = uuidv4()

        $($event_uuid).find('input').attr('value', new_uuid)
        $($event_uuid).find('input')[0].value = new_uuid;
        $($event_uuid).find('input')[0].disabled = true;


      });
    },

    _fillAgents($iframe) {

      $iframe.contentDocument.querySelectorAll('[data-xsd2html2xml-namewithoutns="АгентыСобытия"]')?.forEach(item => {

        let $sender = item.querySelector('[data-xsd2html2xml-namewithoutns="АгентОтправитель"]');
        $($sender).addClass('serviceInfo');

        $($sender).find('[data-xsd2html2xml-namewithoutns="АгентУУИД"] input').setValueIfEmpty(this.sourceOrgGuid);

        $($sender).find('[data-xsd2html2xml-namewithoutns="Представление"] > input').setValueIfEmpty(this.sourceOrgTitle).trigger('change');

      });

    },

    _fillDocHeader($iframe) {

      let _doc_header = $($iframe.contentDocument).find('[data-xsd2html2xml-namewithoutns="ЗаголовокДокумента"]');
      $(_doc_header).addClass('serviceInfo');

      if (!_doc_header)
        return;

      let _doc_ref = $(_doc_header).children('section').children('[data-xsd2html2xml-namewithoutns="СсылкаДокумента"]');

      if (!_doc_ref.length)
        return;

      const uuid = uuidv4()

      if (!_doc_ref[0].querySelector('[data-xsd2html2xml-namewithoutns="ДокументУУИД"] input').value) {
        _doc_ref[0].querySelector('[data-xsd2html2xml-namewithoutns="ДокументУУИД"] input').setAttribute('value', uuid)
        $(_doc_ref).find('[data-xsd2html2xml-namewithoutns="ДокументУУИД"] input').val(uuid).prop('disabled', true).addClass('disabled');
      }

      $(_doc_ref).find('[data-xsd2html2xml-namewithoutns="Представление"] input').prop('disabled', true).addClass('disabled');

      let _creator_ref = $(_doc_header).children('section').children('[data-xsd2html2xml-namewithoutns="СсылкаСоздателя"]');


      $(_creator_ref).find('[data-xsd2html2xml-namewithoutns="АгентУУИД"]:first input:first').setValueIfEmpty(this.sourceOrgGuid).prop('disabled', true).addClass('disabled');
      $(_creator_ref).find('[data-xsd2html2xml-namewithoutns="Представление"]:first input:first').setValueIfEmpty(this.sourceOrgTitle).prop('disabled', true).addClass('disabled').trigger('change');

      $(_doc_header).find('[data-xsd2html2xml-namewithoutns="ВремяСоздания"] input').val(DataFilters.methods.getCurrentDateTimeISO()).trigger('change').prop('disabled', true).addClass('disabled');

      $(_doc_header).find('[data-xsd2html2xml-namewithoutns="СодержитСведенияДСП"] select').prop('disabled', true).addClass('disabled');
      $(_doc_header).find('[data-xsd2html2xml-namewithoutns="ТребуетПодписания"] select').prop('disabled', true).addClass('disabled');
      $(_doc_header).find('[data-xsd2html2xml-namewithoutns="ТребуетРегистрации"] select').prop('disabled', true).addClass('disabled');


      /*let _docElType = $(_doc_header).find('[data-xsd2html2xml-namewithoutns="ВидЭлектронногоДокумента"]');



      $(_docElType).find('[data-xsd2html2xml-namewithoutns="ИД"] input')
          .val(this.getElectronicDocumentsTypeById(this.selector.ElectronicDocumentsType)?.['@attributes']?.['ИД'])
          .prop('disabled', true).addClass('disabled');

      $(_docElType).find('[data-xsd2html2xml-namewithoutns="Название"] input')
          .val(this.getElectronicDocumentsTypeById(this.selector.ElectronicDocumentsType)?.['@attributes']?.['Название'])
          .prop('disabled', true).addClass('disabled');

      $(_doc_header).find('[data-xsd2html2xml-namewithoutns="ДатаСоздания"] input').val(DataFilters.methods.getCurrentDateTimeISO()).trigger('change');*/


    },

    _fillXmlId($iframe) {

      $iframe.contentDocument.querySelectorAll('[data-xsd2html2xml-name="xml:id"]').forEach(item => {

        const parenNodeLocalName = item.parentElement?.getAttribute('data-xsd2html2xml-namewithoutns')

        if (!parenNodeLocalName)
          return;

        const input = item.querySelector('input, select, textarea')

        if (input) {

          const span = item.querySelector(':scope > span')

          if (span)
            span.innerText = 'Идентификатор блока подписываемых данных';
          item.classList.add('serviceInfo')
          input.setAttribute('value', parenNodeLocalName)
          input.setAttribute('disabled', '')

        }

      });


    },

    _hideSelectParentProcess($iframe) {

      $($iframe.contentDocument).find('[data-xsd2html2xml-namewithoutns="ИнформацияОСобытии"]').each((index, item) => {

        let $eventStatus = $(item).find('[data-xsd2html2xml-namewithoutns="СтатусСобытия"]:first select:first').val();

        if ($eventStatus !== 'Инициация') {

          $(item).parents('fieldset:first').find('[data-xsd2html2xml-namewithoutns="ИнформацияОПроцессе"]:first').find('[data-xsd2html2xml-namewithoutns="ИнформацияОВышестоящем"]:first').next('button').hide();

        }

      });

    },

    _addClassToEventBlocks($iframe) {

      $iframe.contentWindow.document.querySelectorAll('[data-xsd2html2xml-xpath-without-ns="/Документ/ДанныеДокумента/СообщенияДокумента"]>section>[data-xsd2html2xml-xpath]').forEach(item => item.classList.add('eventBlock'))

    },

    _hideOtherInformation($iframe) {

      $iframe.contentWindow.document.querySelectorAll('[data-xsd2html2xml-namewithoutns="ИнформацияОСобытии"]').forEach(item => item.classList.add('serviceInfo'))

      $iframe.contentWindow.document.querySelectorAll('[data-xsd2html2xml-namewithoutns="ПредметыСобытия"]').forEach(item => {

        item.childNodes.forEach(child => {

          if (typeof child?.querySelector !== 'function')
            return;

          const eventItem = child?.querySelector('[data-xsd2html2xml-namewithoutns]')
          if (eventItem) {

            eventItem.querySelector('[data-xsd2html2xml-namewithoutns="СсылкаПредмета"]').parentElement.querySelector('button.add').classList.add('hidden')
            eventItem.querySelector('[data-xsd2html2xml-namewithoutns="ВидВладельца"]').classList.add('serviceInfo')
            eventItem.querySelector(':scope > [data-xsd2html2xml-namewithoutns="ВидИД"]').classList.add('serviceInfo')
            eventItem.querySelector(':scope >[data-xsd2html2xml-namewithoutns="ВидНазвание"]').classList.add('serviceInfo')
            eventItem.querySelector('[data-xsd2html2xml-namewithoutns="СостояниеПредмета"]')?.classList?.add('serviceInfo')

            eventItem.querySelector('[data-xsd2html2xml-namewithoutns="ДанныеДляВизуализации"]')?.classList.add('transparentBlock')
            eventItem.querySelector('[data-xsd2html2xml-namewithoutns="ДанныеДляКоординации"]')?.classList.add('transparentBlock')

          }

        })

      })

      $iframe.contentWindow.document.querySelector('[data-xsd2html2xml-xpath-without-ns="/Документ"]')?.classList.add('transparentBlock')
      $iframe.contentWindow.document.querySelector('[data-xsd2html2xml-xpath-without-ns="/Документ/ДанныеДокумента"]')?.classList.add('transparentBlock')
      $iframe.contentWindow.document.querySelector('[data-xsd2html2xml-xpath-without-ns="/Документ/ДанныеДокумента/СообщенияДокумента"]')?.classList.add('transparentBlock')
      const enclosuresEl = $iframe.contentWindow.document.querySelector('[data-xsd2html2xml-xpath-without-ns="/Документ/ДанныеДокумента/ПриложенияДокумента"]')

      if (enclosuresEl.parentElement.nodeName.toLowerCase() === 'section')
        enclosuresEl.parentElement.classList.add('hidden')
      else
        enclosuresEl.classList.add('hidden')


      $iframe.contentWindow.document.querySelector('[data-xsd2html2xml-xpath-without-ns="/Документ/ПодписиДокумента"]')?.parentElement?.querySelector(':scope > button.add').classList.add('hidden')
      $iframe.contentWindow.document.querySelector('[data-xsd2html2xml-xpath-without-ns="/Документ/РегистрацияДокумента"]')?.classList.add('hidden')
      $iframe.contentWindow.document.querySelector('[data-xsd2html2xml-xpath-without-ns="/Документ/РегистрацияДокумента"]')?.parentElement?.querySelector(':scope > button.add').classList.add('hidden')

      // Оборачивающая подпись
      $iframe.contentWindow.document.querySelector('[data-xsd2html2xml-xpath-without-ns="/Документ/Signature"]')?.parentElement?.parentElement?.parentElement?.classList.add('hidden')

    },

    _insertCollapseEventButtons($iframe) {

      const eventCollapseBtnClickMethod = ($ev) => {

        $ev.preventDefault();
        $ev.stopPropagation();
        $ev.stopImmediatePropagation();

        $ev.target.closest('[data-xsd2html2xml-xpath-without-ns]').childNodes.forEach(item => {

          if (item.nodeType === 3)
            return;

          if (item.querySelector('.eventCollapseBtn'))
            return;

          item.classList.toggle('collapsedEvent')

          if (item.classList.contains('collapsedEvent')) {
            $ev.target.textContent = 'Развернуть'
          } else {
            $ev.target.textContent = 'Свернуть'
          }

        })


      }

      $iframe.contentDocument.querySelectorAll('.eventBlock > legend').forEach(item => {

        if (item.closest('.eventBlock')?.hasAttribute('hidden'))
          return;

        if (item.querySelector('.eventCollapseBtn'))
          return;

        let btn = $iframe.contentDocument.createElement('button')
        btn.textContent = 'Свернуть'
        btn.classList.add('p-button')
        btn.classList.add('p-button-outlined')
        btn.classList.add('p-button-sm')
        btn.classList.add('ml-2')
        btn.classList.add('mr-2')
        btn.classList.add('eventCollapseBtn')
        btn.onclick = eventCollapseBtnClickMethod;

        item.childNodes[0].after(btn)

      })

    },

    _insertHeaderControlBar($iframe) {

      if ($iframe.contentDocument.querySelector('.header-control-bar'))
        return;

      const $bar_el = $iframe.contentDocument.createElement('fieldset');
      const legend = $iframe.contentDocument.createElement('legend')
      legend.textContent = 'Документ'
      $bar_el.append(legend)

      function duplicateElement($xpath) {

        function removeAttributes($el) {

          for (let i = 0; i < $el.attributes.length; i++) {

            let attrib = $el.attributes[i].name;

            if (attrib.includes('data-xsd2html2xml'))
              $el.removeAttribute(attrib);

            if (['element-type', 'required', 'onchange'].includes(attrib))
              $el.removeAttribute(attrib);

          }

        }

        const $document_element = $iframe.contentDocument.querySelector('[data-xsd2html2xml-xpath-without-ns="' + $xpath + '"]')
        const $header_element = $document_element.cloneNode(true)
        removeAttributes($header_element)

        const $dsp_sel_el = $header_element.querySelector('select')
        removeAttributes($dsp_sel_el)

        // Берем для шапки начальные значения из HTML (для загрузки из XML->HTML)
        $header_element.querySelector('select').value = $document_element.querySelector('select').value
        $header_element.querySelector('select').dispatchEvent(new Event('change'));

        $header_element.addEventListener('change', function () {
          $document_element.querySelector('select').value = $header_element.querySelector('select').value
          $document_element.querySelector('select').dispatchEvent(new Event('change'));
        });
        $bar_el.append($header_element)

        $iframe.contentDocument.body.insertBefore($bar_el, $iframe.contentDocument.body.firstChild)

        return $bar_el;

      }

      duplicateElement('/Документ/ДанныеДокумента/ЗаголовокДокумента/СодержитСведенияДСП');
      duplicateElement('/Документ/ДанныеДокумента/ЗаголовокДокумента/ТребуетПодписания');
      duplicateElement('/Документ/ДанныеДокумента/ЗаголовокДокумента/ТребуетРегистрации');


      $bar_el.classList.add('header-control-bar')

    },

    fillRegistrationByDummies($iframe) {

      if (!$iframe)
        return;

      function setValue(xpathWithoutNS, value) {

        let searchingElement = $iframe.contentWindow.document.querySelector('[data-xsd2html2xml-xpath-without-ns="' + xpathWithoutNS + '"]')

        if (!searchingElement)
          return

        searchingElement = searchingElement.querySelector('input, select, textarea')
        if (!searchingElement)
          return

        searchingElement.value = value
        searchingElement.setAttribute('value', value)
        $(searchingElement).trigger('change')

      }


      [
        ['/Документ/РегистрацияДокумента/НаправлениеИсходящего/ПредметыСобытия/Исходящий/ДанныеДляКоординации/РеквизитыДокумента/@ДокументУУИД', '00000000-0000-0000-0000-000000000000'],
        ['/Документ/РегистрацияДокумента/НаправлениеИсходящего/ПредметыСобытия/Исходящий/ДанныеДляКоординации/РеквизитыДокумента/@Представление', '-'],
        ['/Документ/РегистрацияДокумента/НаправлениеИсходящего/ПредметыСобытия/Исходящий/ДанныеДляКоординации/РеквизитыДокумента/РегистрацияДокумента/ОрганРегистрации/@АгентУУИД', '00000000-0000-0000-0000-000000000000'],
        ['/Документ/РегистрацияДокумента/НаправлениеИсходящего/ПредметыСобытия/Исходящий/ДанныеДляКоординации/РеквизитыДокумента/РегистрацияДокумента/ОрганРегистрации/@Представление', '-'],
        ['/Документ/РегистрацияДокумента/НаправлениеИсходящего/ПредметыСобытия/Исходящий/ДанныеДляКоординации/РеквизитыДокумента/РегистрацияДокумента/НомерРегистрации', 'Черновик'],
        ['/Документ/РегистрацияДокумента/НаправлениеИсходящего/ПредметыСобытия/Исходящий/ДанныеДляКоординации/РеквизитыДокумента/РегистрацияДокумента/ДатаРегистрации', DataFilters.methods.dateToYMD(new Date())],


        ['/Документ/РегистрацияДокумента/ОграничениеДоступа/ПредметыСобытия/Доступ/ДанныеДляВизуализации/Делопроизводитель/@ФИО', '-'],
        ['/Документ/РегистрацияДокумента/ОграничениеДоступа/ПредметыСобытия/Доступ/ДанныеДляВизуализации/Делопроизводитель/@ИД', '-'],

      ].forEach(item => setValue(item[0], item[1]))


    },

    async getRefs() {

      let response = await this.$xapi.post(
        '/constructor',
        {
          action: 'getRefs',
        },
      );

      this.InformationInteractionsTypes = response.data.InformationInteractionsTypes;
      this.InformationInteractionsEvents = response.data.InformationInteractionsEvents;
      this.ElectronicDocumentsTypes = response.data.ElectronicDocumentsTypes;

    },

    getInformationInteractionTypeById($id) {
      return this.InformationInteractionsTypes.filter(item => item?.['@attributes']?.['ИД'] === $id)[0]
    },

    getInformationInteractionEventById($id) {
      return this.InformationInteractionsEvents.filter(item => item?.['@attributes']?.['ИД'] === $id)[0]
    },

    getElectronicDocumentsTypeById($id) {
      return this.ElectronicDocumentsTypes.filter(item => item?.['@attributes']?.['ИД'] === $id)[0]
    },

    /*setValueIfEmpty(el, value) {

    }*/


    _insertDataAboutProcessIfSelected($iframe, autoSelectProcess) {

      if (!autoSelectProcess)
        return;

      $iframe.contentDocument.querySelectorAll(".selectProcess").forEach(item => item.dispatchEvent(new CustomEvent('passSelectedProcess', {detail: {processInfo: Helper.methods.copyObjectByJSON(autoSelectProcess)}})));


    }
  },
  mounted() {
    this.getRefs();
  },

}
</script>

<style scoped>

</style>